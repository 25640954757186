.planned_table tr,
.planned_table tr td {
  cursor: pointer;
}

.planned_table tr.active,
.planned_table tr.active td {
  background-color: #e8e8e8 !important;
}

.dark .planned_table tr.active,
.dark .planned_table tr.active td {
  background-color: #393939 !important;
}
