.dark {
  // .cds--header-panel .cds--tab-content {
  //   background-color: #262626 !important;
  // }

  .dropdown_request {
    background: #161616;
    .cds--dropdown__wrapper {
      background: #262626;
      border-bottom: 1px solid #393939;
    }

    .cds--list-box__field {
      background: #262626 !important;
    }
  }

  .dropdown {
    background: #161616;

    .cds--dropdown__wrapper {
      background: #161616;
      border-bottom: 1px solid #393939;
    }

    .cds--list-box__field {
      background: #262626 !important;

      .cds--list-box__label {
        width: 285px;
      }
    }
  }

  .form-container .cds--text-input {
    background-color: #262626 !important;
  }
  .geJjXi .cds--header-panel .cds--tab-content {
    background-color: #262626 !important ;
  }
  th {
    background-color: #474747 !important;
  }
  td {
    background-color: #262626 !important;
  }
  a.cds--side-nav__link > .cds--side-nav__link-text {
    color: #fff !important;
  }
  .cds--content-switcher-btn {
    border: 1px solid #474747 !important;
  }
  .fRATWa .plans-description-table-wrapper table tr td {
    border: 1px solid var(--border-border-subtle-00, #474747) !important;
  }
  .cefEWE .plans-description-table-wrapper table tr td {
    border: 1px solid var(--border-border-subtle-00, #474747) !important;
  }
  .cefEWE .case-response-time-table table {
    border: 1px solid var(--border-subtle, #474747) !important;
  }
  .cefEWE .case-response-time-table table tr {
    border: 1px solid var(--border-subtle, #474747) !important;
    color: #fff !important;
  }
  .cefEWE .case-response-time-table table tr td {
    color: #fff !important;
    opacity: unset !important;
  }
  .hRKyqI
    .cds--modal
    .cds--modal-container
    .cds--tab--list[aria-label="Support tabs"]
    .cds--tabs__nav-item--selected {
    background: var(--field-hover, #393939);
  }
  .hRKyqI .cds--modal .cds--modal-container .support-modal-content {
    background-color: #262626 !important;
  }
  .jgLgBD .cds--modal .cds--modal-container .support-modal-content {
    background-color: #262626 !important;
  }
  .kLunCM {
    box-shadow: none !important;
  }
  .gTgWXh {
    box-shadow: none !important;
  }
}
