.incident_table_cont {
  padding-bottom: 0;

  @media screen and (max-width: 425px) {
    padding-bottom: 10%;
  }

  @media screen and (min-width: 426px) and (max-width: 768px) {
    padding-bottom: 8%;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-bottom: 6%;
  }
}

.incident_toolbar_content {
  margin-bottom: 0;
  @media screen and (max-width: 375px) {
    margin-bottom: 14%;
  }
  @media screen and (min-width: 376px) and (max-width: 425px) {
    margin-bottom: 12%;
  }
  @media screen and (min-width: 426px) and (max-width: 768px) {
    margin-bottom: 10%;
  }
}

.incident_table_cont tr,
.incident_table_cont tr td {
  /* background-color: var(--cds-background) !important; */
  cursor: pointer;
}

.incident_table_cont tr.active,
.incident_table_cont tr.active td {
  /* background-color: #e8e8e8 !important; */
}

.dark .incident_table_cont tr.active,
.dark .incident_table_cont tr.active td {
  /* background-color: #393939 !important; */
}

.incident_table_cont tr.active {
  border: 2px solid #2570fe;
}
