@tailwind base;
@tailwind components;
@tailwind utilities;
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline; /* Set to 'inline' to remove 'display: block' */
  vertical-align: middle; /* Set to 'baseline' to remove 'vertical-align: middle' */
}
* {
  scroll-behavior: smooth;
}

