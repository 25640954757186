$feature-flags: (
  ui-shell: true,
  grid-columns-16: false,
);

@use "@carbon/react";
@use "@carbon/styles";
@use "@carbon/grid";
@use "@carbon/themes";
@import "@carbon/layout";
@import "@carbon/colors";
@import "@carbon/feature-flags";
@import "@carbon/motion";
@import "@carbon/type";
@include grid.flex-grid();

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import "./darkTheme.scss";

.cds--header__action:focus,
a.cds--side-nav__link:focus,
.cds--search-input:focus,
.cds--tabs .cds--tabs__nav-link:focus,
.cds--tabs .cds--tabs__nav-link:active {
  outline: unset !important;
  outline-offset: unset !important;
  border: none !important;
  box-shadow: unset !important;
}
.cds--data-table-container {
  padding-block-start: 0 !important;
}
.browser_callpanel {
  padding: 0 !important;
  background-color: #393939;
  .footer_row {
    .footer-content {
      width: 50% !important;
    }
  }
}
.gTgWXh {
  box-shadow: none !important;
}
.call_centerdata {
  border: none !important;
}

.Toastify__toast-container {
  padding-top: 36px !important;
  pointer-events: none;
}

.cds--data-table-container {
  .cds--data-table {
    tr {
      th {
        color: var(--text-text-primary, var(--Text-text-primary, #161616));

        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }
  }
}

.header_noti {
  .iconmotion {
    -moz-animation: ring 4s 0.7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s 0.7s ease-in-out infinite;
    transform-origin: 50% 4px;
  }
}
